import React, { Component } from 'react';
import { firestore } from '../../firebase/firebase';
import OverviewSlider from '../carousel/overview_carousel';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Loader from '../subComponents/loader';
import { DEFAULT_SERVER_URL } from '../../Server';
import {
    isMobile,
    isSafari
} from "react-device-detect";
// import AdSection from '../subComponents/AdSection';
// import ReactHLS from 'react-hls';
import ReactHlsPlayer from 'react-hls-player';
class Watchlivepage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            fetchingPage: true,
        };
        this.state.pageItemDoc = firestore.collection('pages').doc('BResRikKYh5mnQgHeSgU');
        this.defaultConfig = firestore.collection('configurations').doc('default');

    }

    componentDidMount() {
        this.unsubscribeCollection = this.defaultConfig.onSnapshot(this.onConfigUpdate);
        if (this.state.pageItemDoc) {
            this.unsubscribeDoc = this.state.pageItemDoc.onSnapshot(this.onDocUpdate);
        }
    }

    componentWillUnmount() {
        this.unsubscribeDoc();
    }

    onConfigUpdate = (docSnapshot) => {
        //let config = docSnapshot.exists ? docSnapshot.data() : {};
        this.setState({
            fetching: false,
            channelID13: 'channel13',
            channelID23: 'channel23'
        });
    };

    onDocUpdate = (snapshot) => {
        let pageDoc = snapshot.exists ? snapshot.data() : {};
        this.setState({
            fetchingPage: false,
            ...pageDoc,
        });
    };

    render() {
        let { fetching, fetchingPage, coverMedia, title } = this.state;
        if (fetching || fetchingPage) return <Loader />;

        const {
            config,
            // topAd,
            // topAdMedia,
            // isGoogleAd = false
        } = this.props;
        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;
        // const serverURL = config.serverURL() || DEFAULT_SERVER_URL;

        const channels = config.liveStreamChannels();
        var channelList = [];

        // Check if channels is defined and an object
        if (channels && typeof channels === 'object') {
            // Convert channels object to an array of { id, streamURL } objects
            channelList = Object.keys(channels)
                .sort((a, b) => channels[a].order - channels[b].order)
                .map((key) => {
                    return ({
                        id: key,
                        ...channels[key]
                    })
                });
        }

        return (
            <main id="main">
                {/* <AdSection ad={topAd} preloadedAdMedia={topAdMedia} isGoogleAd={isGoogleAd} config={config} /> */}
                <OverviewSlider slides={[coverMedia]} title={title} config={config} />
                <div className="container triple-inset">
                    <div className="row">
                        <div className="col">
                            <Tabs>
                                <TabList>
                                    {
                                        channelList.map((channel, index) =>
                                            <Tab
                                                key={`tab-index-${index}`}
                                                style={{
                                                    width: `${100 / channelList.length}%`,
                                                    border: 'none',
                                                }}
                                            >
                                                <span>{channel.label}</span>
                                            </Tab>
                                        )
                                    }
                                </TabList>
                                {
                                    channelList.map((channel, index) =>
                                        <TabPanel
                                            key={`tab-panel-index-${index}`}
                                        >
                                            <div className="embed-responsive embed-responsive-16by9">
                                                {(isMobile || isSafari) ?
                                                    <video width="400" controls poster={`${mediaServerURL}/media/store-VHviHZ9uO3/media/nel5QssCekdbxTHpZEh6/thumbnail_w600.jpeg`}>
                                                        <source src={channel.streamURL} type="application/x-mpegURL" />
                                                    </video>
                                                    :
                                                    //<ReactHLS url={`https://cdn3.wowza.com/1/cVBReWFDYkhnSExS/RjJrZXo2/hls/live/playlist.m3u8`} />
                                                    // <div>
                                                    //     <iframe
                                                    //         title='Telearuba livestream channel 23'
                                                    //         src="https://cdn3.wowza.com/1/cVBReWFDYkhnSExS/RjJrZXo2/hls/live/playlist.m3u8"
                                                    //     />
                                                    // </div>

                                                    // Current LIVE
                                                    <ReactHlsPlayer
                                                        src={channel.streamURL}
                                                        autoPlay={false}
                                                        controls={true}
                                                        width="100%"
                                                        height="auto"
                                                    />

                                                }
                                            </div>
                                        </TabPanel>
                                    )
                                }
                            </Tabs>
                        </div>
                    </div>
                </div>
            </main >
        );
    }
}

export default Watchlivepage;
